import { get, post } from '../api';
import { InfoUsuario } from '@utils';
import packageJson from '@package';

export const getRelease = () => {
  return get(`/api/releases/${packageJson.version}/${InfoUsuario.get('cdUsuario')}`);
};

export const postUserAlreadySaw = () => {
  return post('/api/releases/user', {
    idUser: InfoUsuario.get('cdUsuario'),
    version: packageJson.version
  });
};