import App from '@app';
import { Body } from '@components';
import {
  Breadcrumb,
  Button,
  ContentHeader,
  Filter,
  Icon,
  PageHeader,
  SearchBar,
  ViewControl,
} from '@components-teammove';
import { getAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import {
  getCamposCustomizaveis,
  reset as resetCamposCustomizaveis,
} from '@ducks/configuracoesUnidadesCamposCustomizaveis';
import {
  getCamposDinamicos,
  reset as resetCamposDinamicos,
} from '@ducks/configuracoesUnidadesCamposDinamicos';
import { getClusters } from '@ducks/configuracoesUnidadesClusters';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import { FILTERS_KEY, getUnidades, reset, setBusca } from '@ducks/unidades';
import { getUnidades as getUnidadesSdk } from '@sdk/Unidades';
import { Filtros, InfoUsuario, TelasNomenclaturas } from '@utils';
import { debounce } from 'debounce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditarUnidade from './Cadastro';
import EditarMultiplas from './EditarMultiplas';
import ListaUnidades from './Lista';
import MapaUnidades from './Mapa';
import { initialFilters } from './rules';

export default function UnidadesLista() {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState(
    sessionStorage.getItem('searchGestaoCarteira') || ''
  );
  const [listingMode, setListingMode] = useState(
    (localStorage.getItem('companies_view_mode') !== 'Clusters' &&
      localStorage.getItem('companies_view_mode')) ||
    'Agrupadores'
  );
  const [savedFilters, setSavedFilters] = useState(Filtros.get(FILTERS_KEY));
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [activeFilters, setActiveFilters] = useState(initialFilters);
  const [sortCriteria, setSortCriteria] = useState();
  const [visibleRegister, setVisibleRegister] = useState(false);
  const [visibleMultipleRegister, setVisibleMultipleRegister] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const dispatch = useDispatch();

  const userTM = InfoUsuario.get('senhaMaster');

  const theme = useSelector(({ app }) => app.get('theme'));

  const {
    unidades,
    loading,
    success,
    total: totalCompanies,
  } = useSelector(({ unidades }) => ({
    unidades: unidades.get('unidades'),
    loading: unidades.get('loadingUnidades'),
    success: unidades.get('successUnidades'),
    total: unidades.get('total'),
  }));

  const {
    agrupadores,
    successAgrupadores,
    clusters,
    successClusters,
    tiposUnidades,
    successTiposUnidades,
    camposDinamicos,
    successCamposDinamicos,
    camposCustomizaveis,
    successCamposCustomizaveis,
  } = useSelector((state) => ({
    agrupadores: state.configuracoesUnidadesAgrupadores.get('agrupadores'),
    successAgrupadores: state.configuracoesUnidadesAgrupadores.get('successAgrupadores'),
    clusters: state.configuracoesUnidadesClusters.get('clusters'),
    successClusters: state.configuracoesUnidadesClusters.get('successClusters'),
    tiposUnidades: state.configuracoesUnidadesTiposUnidades.get('tiposUnidades'),
    successTiposUnidades: state.configuracoesUnidadesTiposUnidades.get('successTiposUnidades'),
    camposDinamicos: state.configuracoesUnidadesCamposDinamicos.get('camposDinamicos'),
    successCamposDinamicos: state.configuracoesUnidadesCamposDinamicos.get('success'),
    camposCustomizaveis: state.configuracoesUnidadesCamposCustomizaveis.get('camposCustomizaveis'),
    successCamposCustomizaveis: state.configuracoesUnidadesCamposCustomizaveis.get('success'),
  }));

  const {
    clustersByGroup,
    successClustersByGroup,
  } = useSelector(({ gestaoCarteira }) => ({
    clustersByGroup: gestaoCarteira.get('clustersByGroup'),
    successClustersByGroup: gestaoCarteira.get('successClustersByGroup'),
  }));

  useEffect(() => {
    setSelecting(false);
    handleSearchChangingNewFilters(Filtros.get(FILTERS_KEY));
    dispatch(getAgrupadores());
    dispatch(getClusters());
    dispatch(getTiposUnidades());
    dispatch(getCamposDinamicos());
    dispatch(getCamposCustomizaveis());

    return () => {
      dispatch(resetCamposDinamicos());
      dispatch(resetCamposCustomizaveis());
      dispatch(reset());
    };
  }, []);

  const transformFieldsToFilters = (
    camposCustomizaveis = [],
    camposDinamicos = []
  ) => {
    const normalizeOptions = (options = []) => {
      if (!Array.isArray(options)) return [];

      return options
        .filter(
          (option) => option !== undefined && option !== null && option !== ''
        )
        .map((option) =>
          typeof option === 'string' ? option.trim() : String(option)
        )
        .filter(Boolean);
    };

    const formatAsSelectOptions = (options = []) => {
      return normalizeOptions(options).map((option) => ({
        label: option,
        value: option,
      }));
    };

    const determineFieldType = (field) => {
      if (
        field.kind === 'SELECT' ||
        (Array.isArray(field.options) && field.options.length > 0)
      ) {
        return 'SELECT';
      }
      return 'TEXT';
    };

    const customFilters = (camposCustomizaveis || []).map((campo) => ({
      type: determineFieldType(campo),
      label: campo.name || '',
      name: campo.field,
      placeholder: `Insira ${campo.name || 'o valor'}`,
      options: formatAsSelectOptions(campo.options),
      filterable: Boolean(campo.filterable),
      source: 'customDynamic',
    }));

    const dynamicFilters = (camposDinamicos || [])
      .filter((campo) => Boolean(campo.filterable))
      .map((campo) => ({
        type: determineFieldType(campo),
        label: campo.title || '',
        name: `dynamicField${campo.id}`,
        placeholder: `Insira ${campo.title || 'o valor'}`,
        filterable: Boolean(campo.filterable),
        source: 'customDynamic',
        options: formatAsSelectOptions(campo.options),
      }));

    return [...customFilters, ...dynamicFilters].sort(
      (a, b) => (a.order || 0) - (b.order || 0)
    );
  };

  useEffect(() => {
    const hasPrimaryData =
      (successAgrupadores && successClusters && successTiposUnidades) ||
      successClustersByGroup;

    const hasCustomData = successCamposCustomizaveis && successCamposDinamicos;

    if (!hasPrimaryData && !hasCustomData) return;

    let baseFilters = [...initialFilters];

    if (hasPrimaryData) {
      baseFilters = baseFilters.map((filter) => {
        switch (filter.name) {
          case 'companyGroups':
            return {
              ...filter,
              options: agrupadores?.map((agrupador) => ({
                label: agrupador.description,
                value: agrupador.id,
              })) || [],
            };

          case 'clusterGroups':
            return {
              ...filter,
              mode: undefined,
              options: clusters?.map((cluster) => ({
                label: cluster.nome,
                value: cluster.id,
              })) || [],
            };

          case 'clusters':
            return {
              ...filter,
              order: 5,
              source: 'clustersByGroup',
              label: 'Cluster',
              options: [
                ...(clustersByGroup?.map((cluster) => ({
                  label: cluster.name,
                  value: cluster.id,
                })) || []),
                { label: 'Sem Cluster', value: -1 },
              ],
            };

          case 'companyKinds':
            return {
              ...filter,
              label: `Tipo de ${companyNomenclature?.nomenclatura || ''}`,
              options: tiposUnidades?.map((tipoUnidade) => ({
                label: tipoUnidade.name,
                value: tipoUnidade.id,
              })) || [],
            };

          default:
            return { ...filter };
        }
      });
    }

    if (hasCustomData) {
      const customFilters = transformFieldsToFilters(
        camposCustomizaveis,
        camposDinamicos
      ).filter((field) => field.filterable);

      const maxOrder = Math.max(...baseFilters.map((baseFilter) => baseFilter.order || 0));
      const newCustomFilters = customFilters
        .filter((newFilter) => !baseFilters.some((baseFilter) => baseFilter.name === newFilter.name))
        .map((filter, index) => ({
          ...filter,
          order: maxOrder + index + 1
        }));

      baseFilters = [...baseFilters, ...newCustomFilters];
    }

    setActiveFilters(baseFilters.sort((a, b) => (a.order || 999) - (b.order || 999)));
  }, [
    successAgrupadores,
    successClusters,
    successTiposUnidades,
    successClustersByGroup,
    successCamposCustomizaveis,
    successCamposDinamicos,
  ]);

  useEffect(() => {
    if (success) {
      handleGetUnidadesDispatch(50, sortCriteria);
    }
  }, [sortCriteria]);

  useEffect(() => {
    if (success && !searchTerm) {
      debounce(() => {
        if (listingMode === 'Agrupadores') {
          handleGetUnidadesDispatch(50, sortCriteria, savedFilters, searchTerm);
        } else {
          handleGetUnidadesDispatch(null, null, savedFilters, searchTerm);
        }
      }, 500)();
    }
  }, [searchTerm]);

  const handleCancelSelect = () => {
    setSelecting(false);
    history.push('#');
  };

  const handleListingByChange = (listingByToSet) => {
    if (selecting) {
      handleCancelSelect();
    }
    if (listingMode !== 'Agrupadores' && listingByToSet === 'Agrupadores') {
      handleGetUnidadesDispatch(50, sortCriteria, savedFilters, searchTerm);
    }
    setListingMode(listingByToSet);
    localStorage.setItem('companies_view_mode', listingByToSet);
  };

  const handleSimpleSearch = (searchParam = searchTerm) => {
    searchParam = searchParam?.trim();
    dispatch(setBusca(searchParam));
    setSearchTerm(searchParam);
    sessionStorage.setItem('searchGestaoCarteira', searchParam);
    if (listingMode === 'Agrupadores') {
      handleGetUnidadesDispatch(50, sortCriteria, savedFilters, searchParam);
    } else {
      handleGetUnidadesDispatch(null, null, savedFilters, searchParam);
    }
  };

  const handleSearch = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
    return handleGetUnidadesDispatch(
      listingMode === 'Mapa' ? null : 50,
      sortCriteria,
      filtros,
      searchTerm
    );
  };

  const handleSearchChangingNewFilters = (filtros) => {
    if (filtros?.descFiltros) {
      Object.keys(filtros.descFiltros).forEach((key) => {
        if (
          !filtros.descFiltros[key] ||
          filtros.descFiltros[key].length === 0
        ) {
          delete filtros.descFiltros[key];
        }
      });
    }

    if (filtros) {
      const { agrupador, tipoUnidade, descFiltros } = filtros;
      Filtros.set('DASH', {
        agrupadorId: agrupador || undefined,
        companyTypeId: tipoUnidade || undefined,
        descFiltros: {
          agrupadorId: descFiltros?.agrupador || undefined,
          companyTypeId: descFiltros?.tipoUnidade || undefined,
        },
      });
    }

    setSavedFilters(filtros);
    handleSearch(filtros);
  };

  const handleGetUnidadesDispatch = (
    limit,
    orderByParam = sortCriteria,
    filtrosParam = savedFilters,
    searchParam = searchTerm
  ) => {
    dispatch(
      getUnidades(
        filtrosParam,
        limit,
        searchParam,
        orderByParam,
        listingMode === 'Agrupadores'
      )
    );
  };

  const handleUnityClick = (unidade) => {
    history.push(`/unidades/${unidade.id}`);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={companyNomenclature.plural}
          breadcrumb={
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                {
                  path: '/unidades',
                  name: `${companyNomenclature.plural}`,
                  current: true,
                },
                {
                  path: '/unidades',
                  name: `Lista d${companyNomenclature.artigo
                    }s ${companyNomenclature.plural.toLowerCase()}`,
                  current: true,
                },
              ]}
            />
          }
          extra={[
            userTM && (
              <Button
                size="small"
                context="header"
                key="0"
                type="secondary"
                icon={<Icon.MDI type="reload" />}
                onClick={() =>
                  getUnidadesSdk(undefined, undefined, {
                    'x-cache-control': 'must-revalidate',
                  })
                }
              >
                Revalidar Cache
              </Button>
            ),
            !selecting && (
              <Button
                size="small"
                context="header"
                key="1"
                type="secondary"
                icon={<Icon.MDI type="filter-outline" />}
                onClick={() => setAreFiltersVisible(true)}
              >
                Filtros
              </Button>
            ),
            InfoUsuario.perm('unidadesEditarMultiplas') &&
            listingMode === 'Agrupadores' && (
              <Button
                size="small"
                context="header"
                key="2"
                type="secondary"
                onClick={() =>
                  selecting ? handleCancelSelect() : setSelecting(true)
                }
              >
                {selecting
                  ? 'Cancelar'
                  : `Selecionar ${companyNomenclature.plural}`}
              </Button>
            ),
            (selecting
              ? InfoUsuario.perm('unidadesEditarMultiplas')
              : InfoUsuario.perm('unidadesCadastro')) && (
              <Button
                size="small"
                context="header"
                key="3"
                type="primary"
                onClick={
                  selecting
                    ? () => setVisibleMultipleRegister(true)
                    : () => setVisibleRegister(true)
                }
              >
                {selecting ? `Mover ${companyNomenclature.plural}` : 'Novo'}
              </Button>
            ),
          ]}
        >
          <SearchBar
            placeholder="Pesquisar"
            initialValue={searchTerm}
            onSearch={handleSimpleSearch}
            activeFiltersProps={{
              filtros: savedFilters,
              onSearch: handleSearchChangingNewFilters,
              filters: activeFilters,
              disabled: selecting,
            }}
            disabled={selecting}
          />
          <ContentHeader
            title={listingMode}
            extra={
              <ViewControl
                options={[
                  {
                    icon: <Icon.TeamMove type="cluster-outlined" />,
                    name: 'Clusters',
                  },
                  {
                    icon: <Icon.MDI type="format-list-bulleted" />,
                    name: 'Agrupadores',
                  },
                  { icon: <Icon.Feather type="map-pin" />, name: 'Mapa' },
                ]}
                current={listingMode}
                onChange={handleListingByChange}
              />
            }
          />
          {listingMode === 'Agrupadores' && (
            <ListaUnidades
              unidades={unidades}
              handleDispatch={handleGetUnidadesDispatch}
              total={totalCompanies}
              orderBy={sortCriteria}
              setOrderBy={setSortCriteria}
              isFiltering={
                savedFilters &&
                Object.keys(savedFilters).length > 0 &&
                savedFilters.descFiltros
              }
              selecting={selecting}
              handleUnityClick={handleUnityClick}
              loading={loading}
              status="active"
              title={companyNomenclature.plural || 'Unidades'}
              sucess={success}
            />
          )}
          {listingMode === 'Clusters' && history.push('/gestaoCarteira')}
          {listingMode === 'Mapa' && (
            <MapaUnidades
              unidades={unidades.filter((unity) => unity.active)}
              handleDispatch={handleGetUnidadesDispatch}
              handleUnityClick={handleUnityClick}
              loading={loading}
              visibleRegister={visibleRegister}
            />
          )}
        </PageHeader>
        {areFiltersVisible && (
          <Filter
            visible={areFiltersVisible}
            onClose={() => setAreFiltersVisible(false)}
            filtros={savedFilters}
            onSearch={handleSearchChangingNewFilters}
            filters={activeFilters.sort((a, b) => a.order - b.order)}
          />
        )}

        {visibleRegister && (
          <EditarUnidade
            visible={visibleRegister}
            onCancel={() => setVisibleRegister(false)}
            title={companyNomenclature.nomenclatura || 'Unidade'}
          />
        )}

        {visibleMultipleRegister && (
          <EditarMultiplas
            visible={visibleMultipleRegister}
            onCancel={() => setVisibleMultipleRegister(false)}
            onSuccess={() => {
              history.push('#');
              handleSimpleSearch();
              setVisibleMultipleRegister(false);
              setSelecting(false);
            }}
            title={companyNomenclature.plural || 'Unidades'}
          />
        )}
      </Body>
    </App>
  );
}