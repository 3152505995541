import styled from 'styled-components';
import { View, Text, Image, Modal, Button, Progress, Row, Column, Radio, Table, Icon } from '@components-teammove';

export const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
`;

export const CardsContainer = styled(Row)`
  margin-top: 30px;
  gap: 12px;
  align-items: stretch;
`;

export const RowWithFlexChildren = styled(Row)`
  > * {
    flex: 1;
  }
`;

export const ContentInfo = styled(View)`
  flex: 1;
`;

export const ContentCampaignCover = styled(View)`
  max-height: 120px;
  max-width: 200px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CampaignCover = styled(Image)`
  border-radius: 3px;

  :hover {
    opacity: 0.9;
  }

`;

export const CardView = styled(Column)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  display: flex;
  padding-top: ${({ positivation }) => positivation ? '0px' : '20px'};
  flex: 1;
  height: 108px;
`;

export const MainText = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  line-height: 38px;
  padding-top: 8px;
`;

export const SecondaryText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
  line-height: 21px;
`;

export const ExtraText = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 19px;
`;

export const CampaignDescription = styled(View)`
  margin-top: 10px;
  color: ${({ theme }) => theme.textColor};
`;

export const ModalCampaignCover = styled(Image)`
  width: 100%;
`;

export const ContentTableSalespeople = styled(View)`
  margin-top: 30px;
  gap: 18px;
`;

export const ContentTableRanking = styled(View)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  min-height: 45px;
  padding-right: 20px;
`;

export const ContentProgression = styled(View)`
  padding-right: 8px;
`;

export const ContentTableRankingPos = styled(View)`
  display: flex;
  align-items: center;
`;

export const TableRankingPos = styled(Text)`
  ${({ highlight }) => highlight && `
    background-color: var(--success-color) !important;
  `}
  color: ${({ theme }) => theme.textColor} !important;
  border-radius: 8px;
  padding: 7px 12px;
`;

export const TableRankingName = styled(Text)`
  flex: 1;
  cursor: pointer;
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const TableRankingNameHidden = styled(Text)`
  padding: 0px 15px;
`;

export const TableRankingPrize = styled(Text)`
  color: var(--success-color) !important;
`;

export const TableRankingEmptyPrize = styled(View)`
  flex: 1;
  align-items: center;
`;

export const IconUser = styled(Icon.Feather)`
  display: flex;
  cursor: pointer;
`;

export const ModalCampaigns = styled(Modal)`
  width: fit-content !important;
  max-height: 700px;

  .ant-modal-body {
    padding: 0px;
    max-height: 700px;
    overflow-y: scroll;
  }
`;

export const ModalContentCampaigns = styled(View)`
  white-space: nowrap;
`;

export const ButtonModalCampaigns = styled(Button)`
  border: none !important;
  box-shadow: none !important;
`;

export const ButtonUnselectCampaign = styled(Button)`
  border: none !important;
  box-shadow: none !important;
`;

export const ContentTitle = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentViewAll = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
`;

export const ViewAll = styled(Text)`
  font-size: 15px;
`;

export const LineProgress = styled(Progress)`
  .ant-progress-outer {
    display: flex;
    align-items: center;
  }
  
  .ant-progress-inner {
    background-color: ${({ theme }) => theme.darkLowlightColor};
  }
  padding-bottom: 11px;
  padding-right: 20px;
`;

export const StyledProgress = styled(Progress)`
  .ant-progress-inner {
    font-size: ${({ smallTextInner }) => smallTextInner ? 16 : 19}px !important;
  }
  .ant-progress-text {
    color: ${({ reachedGoal, isAboveGoal, positivation, theme }) => positivation ? theme.textColor : isAboveGoal ? 'var(--super-success-color)' : reachedGoal ? 'var(--success-color)' : 'var(--warning-color)' } !important;
    font-weight: 700;
  }
  path {
    stroke-width: 10;
  }
`;

export const AverageText = styled(MainText)`
  font-size: ${({ secondaryText }) => secondaryText ? '16px' : '20px'};
  font-weight: 700;
  color: ${({ color, theme }) => color || theme.textColor};
  line-height: 27px;
  padding-top: 3px;
  opacity: ${({ secondaryText }) => secondaryText ? '.5' : '1'};
`;

export const SecondaryTextWhite = styled.span`
  font-size: 16px;
  font-weight: 600;
  padding-bottom:8px;
  color: ${({ theme }) => theme.textColor};
  line-height: 21px;
`;

export const ButtonRadius = styled(Radio.Button)`
  border-radius: 12px !important;
`;

export const TableStyle = styled(Table)`
 &.ant-table {
  .ant-table-content {
    tbody {
      > tr {
          height: 73px;
      }
        td {
          font-size: 16px !important;
      }
  }
}}`;

export const ColumnProjects = styled(Column)`
  justify-content: flex-start;
`;

export const ColoredBall = styled.div`
  border-radius: 50%;
  background: ${({ color, theme })=> color === 'ok' ? 'var(--success-color)' : color === 'alert' ? 'var(--warning-color)' : color === 'critical' ? 'var(--danger-color)' : color === 'n/a' ? `${theme.backgroundColor}` : `${theme.textColor}`} !important;
  width: 8px;
  height: 8px;
`;

export const PercentageText = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: ${({ opacity }) => opacity ? '.5' : '1'};
  }
`;