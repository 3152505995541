import App from '@app';
import {
  Body,
  Breadcrumb,
  Button,
  Column,
  PageHeader,
  Progress,
  Row,
  Skeleton,
  Tag,
} from '@components-teammove';
import {
  finishChecklist,
  getChecklistFinishPreview,
  getChecklistPreview,
  reset,
  resetFinishError,
} from '@ducks/checklists';
import { getMetaAtividades } from '@ducks/configuracoesMetaAtividades';
import {
  InfoUsuario,
  Notification,
  dateToAnsi,
  getCurrentMomentStartOfMonth,
  moment,
} from '@utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ActivityTypeIcon, ActivityTypeIconWrapper } from '../styles';
import {
  AbsoluteDiv,
  AnsweredIcon,
  AnsweredOfTotal,
  ChecklistProgressSummaryTitle,
  ColumnContainer,
  ConditionalContainerQuestion,
  Container,
  ContentContainer,
  ContentTitle,
  GroupContentContainer,
  GroupTitleColumn,
  GroupTitleRow,
  ProgressPercentage,
  QuestionContainer,
  QuestionText,
  WarningIcon,
} from './styles';

/**
 * Checklist Component
 * Displays and manages a checklist for an activity, showing progress, groups, and questions
 * Handles navigation between checklist views and completion of the checklist
 */
export default function Checklist() {
  const { idActivity, idChecklist } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  // Selectors
  const theme = useSelector(({ app }) => app.get('theme'));
  const preview = useSelector(({ checklists }) => checklists.get('checklistPreview'));
  const previewFinish = useSelector(({ checklists }) => checklists.get('checklistFinishPreview'));
  const loadingChecklistPreview = useSelector(({ checklists }) => checklists.get('loadingChecklistPreview'));
  const loadingFinishChecklist = useSelector(({ checklists }) => checklists.get('loadingFinishChecklist'));
  const successFinishChecklist = useSelector(({ checklists }) => checklists.get('successFinishChecklist'));
  const errorFinishChecklist = useSelector(({ checklists }) => checklists.get('errorFinishChecklist'));

  const [toRedirectToRespond, setToRedirectToRespond] = useState(
    sessionStorage.getItem('toRedirectToRespond') || false
  );

  // Navigation helpers
  const navigateToFinishedPage = () => {
    const redirectPath = `/atividades/agenda/${idActivity}/checklist/${idChecklist}/finished`;
    const redirectState = { idTypeActivity: preview.idTypeActivity };
    history.push(redirectPath, redirectState);
  };

  const navigateToAnswerPage = (params = {}) => {
    history.push(`/atividades/agenda/${idActivity}/checklist/${idChecklist}/answer`, params);
  };

  const navigateToFinishPage = () => {
    history.push(`/atividades/agenda/${idActivity}/checklist/${idChecklist}/finish`);
  };

  // Load checklist data on component mount
  useEffect(() => {
    if (idChecklist) {
      dispatch(getChecklistPreview(idChecklist));
      dispatch(getChecklistFinishPreview(idChecklist));
    }

    // Cleanup on unmount
    return () => {
      dispatch(reset());
    };
  }, [idChecklist, dispatch]);

  // Handle successful checklist completion
  useEffect(() => {
    if (successFinishChecklist) {
      console.log('Checklist finished successfully, navigating...', {
        isCancelationChecklist: preview?.isCancelationChecklist,
        toRedirectToRespond
      });

      if (preview?.isCancelationChecklist) {
        dispatch(getMetaAtividades(getCurrentMomentStartOfMonth()));
      }

      // Handle redirect to respond page if needed
      if (toRedirectToRespond === 'true' && !preview?.isCancelationChecklist) {
        setToRedirectToRespond(false);
        sessionStorage.setItem('toRedirectToRespond', false);
        navigateToAnswerPage();
      } else {
        // Force navigation to finished page with a clear message
        Notification.success('Checklist finalizado com sucesso!');
        navigateToFinishedPage();
      }
    }
  }, [successFinishChecklist, preview?.isCancelationChecklist, preview?.idTypeActivity, toRedirectToRespond]);

  // Handle errors during checklist completion
  useEffect(() => {
    if (errorFinishChecklist) {
      if (preview?.isCancelationChecklist) {
        Notification.error('Há perguntas pendentes');
      }
    }

    return () => {
      dispatch(resetFinishError());
    };
  }, [errorFinishChecklist, preview?.isCancelationChecklist]);

  /**
   * Handles the checklist finish action
   * Determines whether to finish directly or navigate to finish page based on configuration
   */
  const handleChecklistFinish = () => {
    // Check if preview and previewFinish are loaded before proceeding
    if (!preview || !previewFinish) {
      Notification.error('Não foi possível finalizar o checklist. Tente novamente.');
      return;
    }

    const templateIsConfigured =
      previewFinish.sendEmail !== 'N' ||
      previewFinish.typeConsultingVision !== 'NAO_PERMITE' ||
      previewFinish.strategicAction !== 'N';

    if (preview?.isCancelationChecklist || !templateIsConfigured) {
      // Direct finish path
      const finishData = {
        date: dateToAnsi(moment()),
        idUserFinish: InfoUsuario.get('cdUsuario'),
      };
      console.log('Dispatching finishChecklist with data:', finishData);

      dispatch(finishChecklist(idActivity, idChecklist, finishData));
    } else {
      dispatch(
        finishChecklist(idActivity, idChecklist, {
          date: dateToAnsi(moment()),
          idUserFinish: InfoUsuario.get('cdUsuario'),
        })
      );
      navigateToFinishPage();
    }

  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={
            <Row gap="14px" align="center">
              {preview?.nameActivity}
              <ActivityTypeIconWrapper color={preview?.colorTypeActivity}>
                <ActivityTypeIcon type={preview?.iconTypeActivity} />
              </ActivityTypeIconWrapper>
            </Row>
          }
          onBack={() => history.push(`/atividades/agenda/${idActivity}`)}
          breadcrumb={
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/atividades/agenda', name: 'Agenda' },
                {
                  path: `/atividades/agenda/${idActivity}`,
                  name: preview?.nameActivity || '...',
                },
                {
                  path: `/atividades/agenda/${idActivity}/checklist/${idChecklist}`,
                  name: 'Checklist',
                  current: true,
                },
              ]}
            />
          }
          extra={[
            <Button
              size="small"
              key="finish"
              loading={loadingFinishChecklist}
              type="primary"
              disabled={
                previewFinish?.statusChecklist === 'CONCLUIDO' ||
                loadingChecklistPreview ||
                !preview ||
                !previewFinish
              }
              onClick={handleChecklistFinish}
            >
              Finalizar Checklist
            </Button>,
          ]}
        >
          <Container>
            <Column gap="12px">
              <ChecklistProgressSummaryTitle>
                Resumo
              </ChecklistProgressSummaryTitle>
              {loadingChecklistPreview ? (
                <Skeleton.LargeBox />
              ) : (
                preview?.progressRiskActivity !== 0 && (
                  <ContentContainer>
                    <Column gap="14px" align="center">
                      <Column gap="9px" align="center">
                        <ContentTitle>Progresso</ContentTitle>
                        <Progress.Star
                          percent={preview?.progressRiskActivity}
                          color={preview?.riskColor}
                        />
                      </Column>
                      <ProgressPercentage>
                        {preview?.progressRiskActivity?.toFixed(2)}%
                      </ProgressPercentage>
                    </Column>
                  </ContentContainer>
                )
              )}
            </Column>
            {loadingChecklistPreview ? (
              <Skeleton.LargeBox />
            ) : (
              preview?.groups?.map(
                (
                  { name, idGroup, totalAsks, totalAsksAnswered, asks },
                  index
                ) => (
                  <Column gap="20px" key={idGroup}>
                    <GroupTitleColumn
                      gap="12px"
                      onClick={() => navigateToAnswerPage({ group: idGroup })}
                    >
                      <GroupTitleRow>
                        <ContentTitle>{name}</ContentTitle>
                        <Tag bgColor={index === 0 ? '#50ABA0' : '#5FB0E3'}>
                          <AnsweredOfTotal>
                            {totalAsksAnswered}/{totalAsks}
                          </AnsweredOfTotal>
                        </Tag>
                      </GroupTitleRow>
                      <Progress
                        percent={(totalAsksAnswered / totalAsks) * 100}
                        showInfo={false}
                        strokeColor={index === 0 ? '#50ABA0' : '#5FB0E3'}
                        strokeWidth={10}
                      />
                    </GroupTitleColumn>
                    <GroupContentContainer>
                      {asks.reduce((acc, question, idx) => {
                        const {
                          id,
                          order,
                          title,
                          answered,
                          idParentQuestion,
                          titleParentQuestion,
                          titleParentQuestionOption,
                        } = question;
                        const previousQuestion = idx > 0 ? asks[idx - 1] : null;
                        const shouldRenderConditionalContainer =
                          !previousQuestion ||
                          previousQuestion.idParentQuestion !==
                          idParentQuestion;
                        const nextQuestionShouldRenderConditionalContainer =
                          asks[idx + 1]
                            ? asks[idx + 1].idParentQuestion !==
                            idParentQuestion
                            : false;

                        acc.push(
                          <ColumnContainer width="100%" key={id}>
                            {shouldRenderConditionalContainer &&
                              idParentQuestion && (
                                <ConditionalContainerQuestion>
                                  {titleParentQuestion}{' '}
                                  {titleParentQuestionOption}
                                </ConditionalContainerQuestion>
                              )}
                            {idParentQuestion && (
                              <AbsoluteDiv
                                renderConditionalTag={
                                  shouldRenderConditionalContainer &&
                                  idParentQuestion
                                }
                              />
                            )}
                            <QuestionContainer
                              isFirstIndex={idx === 0}
                              nextQuestionShouldRenderConditionalTag={
                                nextQuestionShouldRenderConditionalContainer
                              }
                              renderConditionalTag={
                                shouldRenderConditionalContainer &&
                                idParentQuestion
                              }
                              isConditional={idParentQuestion}
                              answered={answered}
                              onClick={() => navigateToAnswerPage({ question: id })}
                            >
                              <QuestionText>
                                {order + 1} - {title}
                              </QuestionText>
                              {answered ? (
                                <AnsweredIcon type="check" />
                              ) : (
                                <WarningIcon type="alert-triangle" />
                              )}
                            </QuestionContainer>
                          </ColumnContainer>
                        );

                        return acc;
                      }, [])}
                    </GroupContentContainer>
                  </Column>
                )
              )
            )}
          </Container>
        </PageHeader>
      </Body>
    </App>
  );
}
