import React, { useEffect, useState } from 'react';
import { CollapseButton, CollapsibleContainer } from './styles';

const Collapsible = ({ defaultCollapsed = true, collapsed = defaultCollapsed, onChange, collapsedView, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);

  return(
    <CollapsibleContainer collapsed={isCollapsed}>
      <CollapseButton onClick={() => onChange ? onChange(!isCollapsed) : setIsCollapsed(!isCollapsed)} type='chevron-down'/>
      {isCollapsed && collapsedView ? collapsedView : children}
    </CollapsibleContainer>
  );
};

export default Collapsible;