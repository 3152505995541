import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import App from '@app';
import { Body, PageHeader, View, Breadcrumb, Text, Modal, Table, SearchBar, Radio, Skeleton, Row, Column, Grid, Icon, Tooltip } from '@components-teammove';

import { getCampaign, resetCampaign } from '@ducks/campanhaDetalhe';
import { changeUserChild } from '@ducks/campanhaLista';
import { formatDate, formatDecimal, InfoUsuario, formatCurrency, TelasNomenclaturas, LocaleString } from '@utils';
import { ContentInfo, ColumnProjects, TableStyle, ButtonRadius, SecondaryTextWhite, ContentCampaignCover, CampaignCover, CampaignDescription, ModalCampaignCover, ContentTableSalespeople, ContentTableRanking, ContentProgression, TableRankingPrize, ContentTableRankingPos, TableRankingPos, TableRankingName, TableRankingNameHidden, TableRankingEmptyPrize, IconUser, ModalCampaigns, ModalContentCampaigns, ButtonModalCampaigns, ButtonUnselectCampaign, ContentTitle, ContentViewAll, CardsContainer, AverageText, StyledProgress, SpaceBetweenRow, LineProgress, MainText, CardView, SecondaryText, ExtraText, PercentageText, ColoredBall } from './styles';
import CampanhaBreadcrumb from './CampanhaBreadcrumb';
import CampanhaItens from '../CampanhaLista/CampanhaItens';
import Progression from '../CampanhaLista/CampanhaItens/Progression';
import { filterRankingBySearch, filterRankingToShortList } from './rules';
import { getValueFromCampaignFormat } from '../CampanhaLista/rules';

export default function CampanhaDetalhe() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const user = InfoUsuario.get();
  const permVisualizarValores = InfoUsuario.perm('campanhasVendasVisualizarValores');

  const theme = useSelector(({ app }) => app.get('theme'));

  const campaign = useSelector(({ campanhaDetalhe }) => campanhaDetalhe.get('campaign'));
  const success = useSelector(({ campanhaDetalhe }) => campanhaDetalhe.get('success'));
  const userChild = useSelector(({ campanhaLista }) => campanhaLista.get('userChild'));
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [primaryCampaign, setPrimaryCampaign] = useState(null);
  const [primaryCampaignChosen, setPrimaryCampaignChosen] = useState(true);
  const [visibleCover, setVisibleCover] = useState(false);
  const [visibleCampaigns, setVisibleCampaigns] = useState(false);
  const [ranking, setRanking] = useState(null);
  const [searchRanking, setSearchRanking] = useState('');
  const [indexRoleRanking, setIndexRoleRanking] = useState(-1);
  const [viewAllRanking, setViewAllRanking] = useState(false);
  
  useEffect(() => {
    handleSearchCampaing(id);
    return () => handleResetCampaign();
  }, []);

  useEffect(() => {
    if (success) {
      if (campaign?.externalId === id) {
        setPrimaryCampaign(campaign);
        setPrimaryCampaignChosen(true);
        setBreadcrumb(null);
      } else {
        setPrimaryCampaignChosen(false);
        setBreadcrumb(primaryCampaign ? CampanhaBreadcrumb([primaryCampaign], handleClickItemBreadcrumb) : null);
      }
      setIndexRoleRanking(0);
    }
  }, [success]);

  useEffect(() => {
    const rankingOriginal = getOriginalRankingFromRoles();
    const rankingFiltered = filterRankingToShortList(user, rankingOriginal);
    
    if (rankingFiltered.length > 0 && rankingOriginal.length > 0 &&  rankingFiltered.length === rankingOriginal.length) {
      handleChangeRankingView(true, rankingOriginal);
    } else {
      handleChangeRankingView(false, rankingFiltered);
    }

  }, [indexRoleRanking]);

  const handleSearchCampaing = (externalId) => {
    dispatch(getCampaign(externalId, userChild?.cd_usuario || user.cdUsuario, userChild?.idApi || user.idApi));
  };

  const handleResetCampaign = () => {
    dispatch(resetCampaign());
  };

  const handleChangeUserChild = (userChild) => {
    dispatch(changeUserChild(userChild));
  };

  const handleSearchRanking = (text) => {
    setSearchRanking(text);
  };

  const handleBackToPrimaryCampaign = () => {
    handleChangeCampaign(primaryCampaign);
  };

  const handleClickItemBreadcrumb = (externalId) => {
    handleChangeCampaign({ externalId });
  };

  const handleChangeCampaign = ({ externalId }) => {
    handleSearchCampaing(externalId);
    handleResetVars();
  };

  const handleResetVars = () => {
    setBreadcrumb(null);
    setVisibleCover(false);
    setVisibleCampaigns(false);
    setRanking(null);
    setSearchRanking('');
    setIndexRoleRanking(-1);
    setViewAllRanking(false);
  };

  const handleChangeRankingView = (viewAllRanking, ranking) => {
    setRanking(ranking);
    setViewAllRanking(viewAllRanking);
  };

  const getOriginalRankingFromRoles = () => {
    return campaign?.roles[indexRoleRanking]?.values || [];
  };

  const handleSelectRanking = (ranking) => {
    handleChangeUserChild(ranking.usuario);
    history.goBack();
  };

  const customTooltipContent = (positivationsInfo) => {
    if(!positivationsInfo) return null;
    const { totalGoal, totalValue, totalPercentageGoal: goalTotalPercentage, totalValueProjection, totalValueProjectionPercentageGoal } = positivationsInfo;

    const usesProjection = campaign?.usesProjection;

    const totalPercentageGoal = usesProjection ? totalValueProjectionPercentageGoal : goalTotalPercentage;

    const percentageOfNoPositivations = 100 - totalPercentageGoal;
    const totalValueNoPositivation = totalGoal - (usesProjection ? totalValueProjection : totalValue);

    return (
      <Column>
        <Row gap='8px' align='center'>
          <Text>Total de clientes: {LocaleString(totalGoal)}</Text>
        </Row>
        <Row gap='4px' align='center'>
          <ColoredBall color='ok'/>
          <Row gap='4px'>
            <PercentageText>{LocaleString(totalValue)} Positivados</PercentageText>
            <PercentageText opacity>{totalPercentageGoal?.toFixed()}%</PercentageText>
          </Row>
        </Row>
        <Row gap='4px' align='center'>
          <ColoredBall color='critical'/>
          <Row gap='4px'>
            <PercentageText>{LocaleString(totalValueNoPositivation)} Não positivados</PercentageText>
            <PercentageText opacity>{percentageOfNoPositivations?.toFixed()}%</PercentageText>
          </Row>
        </Row>
      </Column>
    );
  };

  const customTooltipContentForTable = (positivationsInfo) => {
    if(!positivationsInfo) return null;
    const { goal, value, percentageGoal, valueProjection, valueProjectionPercentageGoal } = positivationsInfo;

    const usesProjection = campaign?.usesProjection;

    const totalPercentageGoal = usesProjection ? valueProjectionPercentageGoal : percentageGoal;

    const percentageOfNoPositivations = 100 - totalPercentageGoal;
    const totalValueNoPositivation = goal - (usesProjection ? valueProjection : value);

    return (
      <Column>
        <Row gap='8px' align='center'>
          <Text>Total de clientes: {LocaleString(goal)}</Text>
        </Row>
        <Row gap='4px' align='center'>
          <ColoredBall color='ok'/>
          <Row gap='4px'>
            <PercentageText>{LocaleString(value)} Positivados</PercentageText>
            <PercentageText opacity>{totalPercentageGoal?.toFixed()}%</PercentageText>
          </Row>
        </Row>
        <Row gap='4px' align='center'>
          <ColoredBall color='critical'/>
          <Row gap='4px'>
            <PercentageText>{LocaleString(totalValueNoPositivation)} Não positivados</PercentageText>
            <PercentageText opacity>{percentageOfNoPositivations?.toFixed()}%</PercentageText>
          </Row>
        </Row>
      </Column>
    );
  };
  
  const columns = [
    {
      title: 'Pos',
      dataIndex: 'pos',
      width: '10px',
      align: 'center',
      sorter: (a, b) => {
        return campaign?.relatedCampaigns?.length > 0 ? a.relatedCampaignsGoalProjectionPosition - b.relatedCampaignsGoalProjectionPosition : a.valueProjectionPosition - b.valueProjectionPosition;
      },
      render: (text, ranking) => (
        <Row justify='space-between' align='center' gap='25px'>
          <ContentTableRankingPos>
            {(campaign?.relatedCampaigns?.length > 0 && ranking.relatedCampaignsGoalProjectionPrize) || (!campaign?.relatedCampaigns && ranking.valueProjectionPrize) ? (
              <TableRankingPos highlight>
                {campaign?.relatedCampaigns?.length > 0 ? ranking.relatedCampaignsGoalProjectionPosition : ranking.valueProjectionPosition}°
              </TableRankingPos>
            ) : (
              <TableRankingPos>
                {campaign?.relatedCampaigns?.length > 0 ? ranking.relatedCampaignsGoalProjectionPosition : ranking.valueProjectionPosition}°
              </TableRankingPos>
            )}
          </ContentTableRankingPos>
          {user.hierarquia?.includes(ranking.usuario?.cd_usuario) && <IconUser type="user" size={24} color={theme['@global-action-color']}/>}
        </Row>
      ),
    },
    {
      title: campaign?.roles[indexRoleRanking]?.role.name || 'Usuário',
      dataIndex: 'setor',
      width: '40%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, ranking) => (
        <>
          {(permVisualizarValores) || (user.hierarquia?.includes(ranking.usuario?.cd_usuario)) ? 
            <ContentTableRanking>
              <TableRankingName>{ranking.name}</TableRankingName>
              {campaign?.relatedCampaigns && (
                <ContentProgression>
                  <Progression 
                    total={campaign?.relatedCampaigns.length} 
                    achieved={ranking.relatedCampaignsGoalProjectionAchieved} 
                    achievedGoal={ranking.relatedCampaignsGoalProjectionAchievedGoal} 
                    width={47} 
                    useStatus
                  />
                </ContentProgression>
              )}
              {ranking.relatedCampaignsGoalProjectionAchieved}
              {campaign?.relatedCampaigns && '/'}
              {campaign?.relatedCampaigns?.length}
            </ContentTableRanking> : <TableRankingNameHidden>-</TableRankingNameHidden>}
        </>
      ),
    },
    {
      title: 'Meta',
      dataIndex: 'meta',
      align: 'left',
      sorter: (a, b) => a.goal - b.goal,
      render: (text, ranking) => (
        (permVisualizarValores) || (user.hierarquia?.includes(ranking.usuario?.cd_usuario)) ? <Text>{getValueFromCampaignFormat(campaign.format, ranking.goal)}</Text> : <TableRankingEmptyPrize><Text>-</Text></TableRankingEmptyPrize>
      ),
    },
    {
      title: 'Realizado',
      dataIndex: 'realizado',
      align: 'left',
      sorter: (a, b) => a.value - b.value,
      render: (text, ranking) => (
        (permVisualizarValores) || (user.hierarquia?.includes(ranking.usuario?.cd_usuario)) ? <Text>{getValueFromCampaignFormat(campaign.format, ranking.value)}</Text> : <TableRankingEmptyPrize><Text>-</Text></TableRankingEmptyPrize>
      ),
    },
    campaign?.usesProjection && {
      title: 'Projetado',
      dataIndex: 'projetado',
      align: 'left',
      sorter: (a, b) => a.valueProjection - b.valueProjection,
      render: (text, ranking) => (
        (permVisualizarValores) || (user.hierarquia?.includes(ranking.usuario?.cd_usuario)) ? <Text>{getValueFromCampaignFormat(campaign.format, ranking.valueProjection)}</Text> : <TableRankingEmptyPrize><Text>-</Text></TableRankingEmptyPrize>
      ),
    },
    {
      title: '%',
      dataIndex: 'percentual',
      align: 'left',
      sorter: (a, b) => campaign?.usesProjection ? 
        (a.valueProjectionPercentageGoal - b.valueProjectionPercentageGoal) :
        (a.percentageGoal - b.percentageGoal),
      render: (text, ranking) => {
        const { percentageGoal, usuario, valueProjectionPercentageGoal } = ranking;
        const percentageGoalByUsesProjection = campaign?.usesProjection ? valueProjectionPercentageGoal : percentageGoal;
        const isReachedGoal = percentageGoalByUsesProjection >= 100;
        
        return (
          (permVisualizarValores) || (user.hierarquia?.includes(usuario?.cd_usuario)) ? 
            (
              <Column gap='10px'>
                <Text>
                  {formatDecimal(
                campaign?.usesProjection ? valueProjectionPercentageGoal : percentageGoal, 2
                  )}
                </Text>
                <LineProgress percent={percentageGoalByUsesProjection} showInfo={false} strokeColor={isReachedGoal ? 'var(--success-color)' : 'var(--warning-color)'} strokeWidth={7} averageColor='var(--warning-color)'/>
              </Column>
            ) : 
            <TableRankingEmptyPrize><Text>-</Text></TableRankingEmptyPrize>
        );
      }
    },
    campaign?.hasPrizes && {
      title: 'Premiação',
      dataIndex: 'premiacao',
      width: '10%',
      align: 'left',
      sorter: (a, b) => {
        return campaign?.relatedCampaigns?.length > 0 ? 
          a.relatedCampaignsGoalProjectionPrize - b.relatedCampaignsGoalProjectionPrize : 
          parseInt(a.valueProjectionPrize?.slice(3)) - parseInt(b.valueProjectionPrize?.slice(3));
      },
      render: (text, ranking) => (
        (campaign?.relatedCampaigns?.length > 0 && ranking.relatedCampaignsGoalProjectionPrize) || 
        (!campaign?.relatedCampaigns && ranking.valueProjectionPrize) ? 
          <TableRankingPrize>
            {campaign?.relatedCampaigns?.length > 0 ? ranking.relatedCampaignsGoalProjectionPrize : ranking.valueProjectionPrize}
          </TableRankingPrize> : 
          <TableRankingEmptyPrize><Text>-</Text></TableRankingEmptyPrize> 
      ),
    },
    {
      title: 'Positivação',
      dataIndex: 'positivacao',
      width: '10%',
      align: 'left',
      sorter: (a, b) => a.valueProjectionPosition - b.valueProjectionPosition,
      render: (text, ranking) => {
        const { usuario } = ranking;
        const rankingPositivations = campaign?.roles[indexRoleRanking]?.positivationCampaignValues.find((posCampaignValue) => posCampaignValue?.usuario?.cd_usuario === usuario?.cd_usuario) || {};
        const { percentageGoal, valueProjectionPercentageGoal } = rankingPositivations;

        const trueValuePercentageGoal = campaign?.usesProjection ? valueProjectionPercentageGoal : percentageGoal;
        const reachedProjectionPercentageGoal = trueValuePercentageGoal >= 100;
        const isAboveProjectionPercentageGoal = trueValuePercentageGoal > 120;
        
        return (
          <Tooltip title={customTooltipContentForTable(rankingPositivations)}>
            <StyledProgress 
              positivation
              smallTextInner
              width={60} 
              type='circle' 
              reachedGoal={reachedProjectionPercentageGoal}
              isAboveGoal={isAboveProjectionPercentageGoal}
              strokeColor={isAboveProjectionPercentageGoal ? 'var(--super-success-color)' : reachedProjectionPercentageGoal ? 'var(--success-color)' : 'var(--warning-color)'} 
              percent={String(trueValuePercentageGoal).includes('.') ? trueValuePercentageGoal?.toFixed(0) : trueValuePercentageGoal} 
              format={(percent) => {
                const realPercent = String(trueValuePercentageGoal).includes('.') ? Math.floor(trueValuePercentageGoal) : trueValuePercentageGoal;
                return String(realPercent > 100 ? realPercent : percent).replace('.', ',') + '%';
              }}
              averageColor='var(--warning-color)'
            />
          </Tooltip>
        );
      }
    }
  ].filter(Boolean);
    
  const Total = () => {
    const usePositivation = campaign?.roles[indexRoleRanking]?.positivationCampaignTotals;
    const useProjection = campaign?.usesProjection;

    const { totalGoal, totalValue, totalValueProjection, totalPercentageGoal, totalValueProjectionPercentageGoal } = campaign?.roles[indexRoleRanking]?.totals;
    const { 
      totalGoal: totalGoalPos,
      totalPercentageGoal: totalPercentageGoalPos,
      totalValue: totalValuePos,
      totalValueProjection: totalValueProjectionPos,
      totalValueProjectionPercentageGoal: totalValueProjectionPercentageGoalPos,
    } = campaign?.roles[indexRoleRanking]?.positivationCampaignTotals;

    const reachedPercentageGoalProjection = totalPercentageGoal >= 100;
    const reachedProjectionPercentageGoal = totalValueProjectionPercentageGoal >= 100;
    const isAboveProjectionPercentageGoal = totalValueProjectionPercentageGoal > 120;

    const totalValuePercentageGoalPos = useProjection ? totalValueProjectionPercentageGoalPos : totalPercentageGoalPos;
    const reachedPositivationPercentageGoal = totalValuePercentageGoalPos >= 100;
    const isAbovePositivationPercentageGoal = totalValuePercentageGoalPos > 120;

    return (
      <CardsContainer>
        <CardView>
          <SecondaryText opacity={.5}>Meta</SecondaryText>
          <MainText>{formatCurrency(totalGoal)}</MainText>
        </CardView>
        <CardView>
          <SecondaryText opacity={.5}>Realizado</SecondaryText>
          <MainText>{formatCurrency(totalValue)}</MainText>
        </CardView>
        <CardView>
          <Row align='center'>
            <Column justify='space-between' flex={1}>
              <SecondaryTextWhite>Já completamos</SecondaryTextWhite>
              <LineProgress percent={totalPercentageGoal} showInfo={false} strokeColor={reachedPercentageGoalProjection ? 'var(--success-color)' : 'var(--warning-color)'} strokeWidth={10} averageColor='var(--warning-color)'/>
              <ExtraText>{totalGoal > totalValue ? 'Falta' : 'Sobra'} {formatCurrency(Math.abs(totalGoal - totalValue))}</ExtraText>  
            </Column>
            <AverageText color={reachedPercentageGoalProjection ? 'var(--success-color)' : 'var(--warning-color)'}>{formatDecimal(totalPercentageGoal, 2)}%</AverageText>
          </Row>
        </CardView>
        {usePositivation && ( 
          <Tooltip title={customTooltipContent(campaign?.roles[indexRoleRanking]?.positivationCampaignTotals)} placement='topLeft'>
            <CardView>       
              <SpaceBetweenRow>
                <ColumnProjects justify='center'>
                  <SecondaryText opacity={.5}>Positivação</SecondaryText>
                  <AverageText>{LocaleString(useProjection ? totalValueProjectionPos : totalValuePos)}</AverageText>
                  <ExtraText secondaryText>de {LocaleString(totalGoalPos)} {companyNomenclature.plural}</ExtraText>
                </ColumnProjects>
                <StyledProgress 
                  positivation
                  width={70} 
                  type='circle' 
                  reachedGoal={reachedPositivationPercentageGoal}
                  isAboveGoal={isAbovePositivationPercentageGoal}
                  strokeColor={isAbovePositivationPercentageGoal ? 'var(--super-success-color)' : reachedPositivationPercentageGoal ? 'var(--success-color)' : 'var(--warning-color)'} 
                  percent={String(totalValuePercentageGoalPos).includes('.') ? totalValuePercentageGoalPos?.toFixed(0) : totalValuePercentageGoalPos} 
                  format={(percent) => {
                    const realPercent = String(totalValuePercentageGoalPos).includes('.') ? Math.floor(totalValuePercentageGoalPos) : totalValuePercentageGoalPos;
                    return String(realPercent > 100 ? realPercent : percent).replace('.', ',') + '%';
                  }}
                  averageColor='var(--warning-color)'
                />
              </SpaceBetweenRow>
            </CardView>
          </Tooltip>
        )}
        {useProjection && (
          <CardView>
            <SpaceBetweenRow>
              <ColumnProjects justify='center'>
                <SecondaryText opacity={.5}>Projetado</SecondaryText>
                <AverageText>{formatCurrency(totalValueProjection)}</AverageText>
              </ColumnProjects>
              <StyledProgress 
                width={70} 
                type='circle' 
                reachedGoal={reachedPercentageGoalProjection}
                isAboveGoal={isAboveProjectionPercentageGoal}
                strokeColor={isAboveProjectionPercentageGoal ? 'var(--super-success-color)' : reachedProjectionPercentageGoal ? 'var(--success-color)' : 'var(--warning-color)'} 
                percent={String(totalValueProjectionPercentageGoal).includes('.') ? totalValueProjectionPercentageGoal?.toFixed(0) : totalValueProjectionPercentageGoal} 
                format={(percent) => {
                  const realPercent = String(totalValueProjectionPercentageGoal).includes('.') ? Math.round(totalValueProjectionPercentageGoal) : totalValueProjectionPercentageGoal;
                  return String(realPercent > 100 ? realPercent : percent).replace('.', ',') + '%';
                }}
                averageColor='var(--warning-color)'
              />
            </SpaceBetweenRow>
          </CardView>
        )}
      </CardsContainer>
    );
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/campanhas/resultados', name: 'Campanhas' },
                ...(breadcrumb?.routes?.map(({ name, externalId }) => ({ path: '/campanha/' + externalId, name })) || []),
                { path: '/campanha/' + id, name: campaign?.name, current: true }
              ]}
            />
          )}
          title={success && campaign && primaryCampaign ? 
            <ContentTitle>
              <Text>{campaign.name}</Text>
              {!primaryCampaignChosen && <ButtonUnselectCampaign type='icon' icon={<Icon.CloseCircleOutlined />} onClick={() => handleBackToPrimaryCampaign()}/>}
            </ContentTitle> : null
          }
          onBack={() => window.history.back()}
          extra={success && campaign && primaryCampaign?.relatedCampaigns ? <ButtonModalCampaigns type='secondary' size='small' context='header' icon={<Icon.UnorderedListOutlined />} onClick={() => setVisibleCampaigns(true)}>{primaryCampaign?.relatedCampaignsLabel || 'Campanhas'}</ButtonModalCampaigns> : null}
        >
          {success && campaign ? <View>
            <View horizontal>
              <ContentInfo>
                <Text>Período {formatDate(campaign.startAt)} à {formatDate(campaign.endAt)}</Text>
                <CampaignDescription dangerouslySetInnerHTML={{ __html: campaign.description }} />
              </ContentInfo> 
              <View>
                <ContentCampaignCover onClick={() => setVisibleCover(true)}>
                  <CampaignCover src={campaign.image} alt="Capa da Campanha" />
                </ContentCampaignCover>
              </View>
            </View>

            {campaign?.roles[indexRoleRanking] && <Total/>}
            
            {campaign.roles?.length > 0 &&
            <ContentTableSalespeople>
              <Grid numberOfColumns={2} align='center'>
                <SearchBar 
                  initialValue={searchRanking}
                  onSearch={handleSearchRanking} 
                  marginLess
                />
                <Row gap='12px' align='center' justify='flex-end'>
                  <Text>Agrupador:</Text>
                  <Radio.Group buttonStyle='solid' value={indexRoleRanking} onChange={(value) => setIndexRoleRanking(value)}>
                    {campaign.roles &&
                          campaign.roles.map(({ role }, index) => (
                            <ButtonRadius size='small' context='header' key={role.id} value={index}>
                              {role.name}
                            </ButtonRadius>
                          )
                          )}
                  </Radio.Group>
                </Row>
              </Grid>
              
              <TableStyle 
                rowKey="position"
                queries={{ 0: '(max-width: 599px)', 1: '(min-width: 600px) and (max-width: 740px)', 2: '(min-width: 741px) and (max-width: 1070px)', 3: '(min-width: 1071px)' }}
                columns={columns.slice(0, primaryCampaignChosen ? columns.length : columns.length - 1)}
                dataSource={filterRankingBySearch(searchRanking, ranking)}
                hidePagination
                onRow={(ranking, rowIndex) => {
                  return {
                    onClick: () => user.hierarquia?.includes(ranking.usuario?.cd_usuario) && user.cdUsuario !== ranking.usuario?.cd_usuario ? handleSelectRanking(ranking) : null
                  };
                }}
              />

              {!viewAllRanking && searchRanking.length === 0 && campaign?.roles[indexRoleRanking]?.role?.allowToSeeAll && 
              <ContentViewAll onClick={() => handleChangeRankingView(true, getOriginalRankingFromRoles())}>
                <ButtonRadius size='small' context='header' >
                  Ver todos
                </ButtonRadius>
              </ContentViewAll>}

            </ContentTableSalespeople>}

            <Modal
              visible={visibleCover}
              title='Capa'
              footer={null}
              onCancel={() => setVisibleCover(false)}
            >
              <ModalCampaignCover src={campaign.image} />
            </Modal>

            <ModalCampaigns
              visible={visibleCampaigns}
              title='Campanhas'
              footer={null}
              centered
              onCancel={() => setVisibleCampaigns(false)}
            >
              <ModalContentCampaigns>
                <CampanhaItens
                  campaigns={primaryCampaign?.relatedCampaigns}
                  onClick={handleChangeCampaign}
                />
              </ModalContentCampaigns>

            </ModalCampaigns>

          </View> : (
            <Column gap='18px'>
              <Row justify='space-between'>
                <Column gap='12px'>
                  <Skeleton.MediumLine/>
                  <Skeleton.MinorLine/>
                </Column>
                <Skeleton width='200px' height='200px'/>
              </Row>
              <Grid numberOfColumns={4} gap='12px'>
                <Skeleton height='200px'/>
                <Skeleton height='200px'/>
                <Skeleton height='200px'/>
                <Skeleton height='200px'/>
              </Grid>
              <Skeleton.SearchBar/>
              <Table loading/>
            </Column>
          )}
        </PageHeader>        
      </Body>  
    </App>  
  );
}